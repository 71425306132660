import { numberFormat, decimalPointFormat } from "./functions"

// show/hide section by ad type
export function showHideSectionByAdType({ formEle, adType }) {
	let sale, rent
	// set ad type
	sale = 1
	rent = 2
	// show/hide sections
	if (adType == sale) {
		// features
		propertyFeatures({ form: formEle, boolean: true })
		// rent type
		propertyRentType({ form: formEle, boolean: false })
	} else if (adType == rent) {
		// features
		propertyFeatures({ form: formEle, boolean: false })
		// rent type
		propertyRentType({ form: formEle, boolean: true })
	} else {
		// features
		propertyFeatures({ form: formEle, boolean: false })
		// rent type
		propertyRentType({ form: formEle, boolean: false })
	}
}

// show/hide section by property type
export function showHideSectionByPropertyType({formEle, propertyType }) {
	let new_building, old_building, country_house, office, garage, land, commercial
	// set property type
	new_building = 1
	old_building = 2
	country_house = 3
	office = 4
	garage = 5
	land = 6
	commercial = 7
	// show/hide sections
	if (propertyType == new_building || propertyType == old_building) {
		// floor
		propertyFloor({ form: formEle, boolean: true })
		// area
		propertyArea({ form: formEle, boolean: true })
		// area land
		propertyAreaLand({ form: formEle, boolean: false })
		// room
		propertyRoom({ form: formEle, boolean: true })
		// repair
		propertyRepair({ form: formEle, boolean: true })
		// building type
		propertyBuildingType({ form: formEle, boolean: false })
	} else if (propertyType == country_house) {
		// floor
		propertyFloor({ form: formEle, boolean: false })
		// area
		propertyArea({ form: formEle, boolean: true })
		// area land
		propertyAreaLand({ form: formEle, boolean: true })
		// room
		propertyRoom({ form: formEle, boolean: true })
		// repair
		propertyRepair({ form: formEle, boolean: true })
		// building type
		propertyBuildingType({ form: formEle, boolean: false })
	} else if (propertyType == office) {
		// floor
		propertyFloor({ form: formEle, boolean: false })
		// area
		propertyArea({ form: formEle, boolean: true })
		// area land
		propertyAreaLand({ form: formEle, boolean: false })
		// room
		propertyRoom({ form: formEle, boolean: true })
		// repair
		propertyRepair({ form: formEle, boolean: true })
		// building type
		propertyBuildingType({ form: formEle, boolean: true })
	} else if (propertyType == garage) {
		// floor
		propertyFloor({ form: formEle, boolean: false })
		// area
		propertyArea({ form: formEle, boolean: true })
		// area land
		propertyAreaLand({ form: formEle, boolean: false })
		// room
		propertyRoom({ form: formEle, boolean: false })
		// repair
		propertyRepair({ form: formEle, boolean: false })
		// building type
		propertyBuildingType({ form: formEle, boolean: false })
	} else if (propertyType == land) {
		// floor
		propertyFloor({ form: formEle, boolean: false })
		// area
		propertyArea({ form: formEle, boolean: false })
		// area land
		propertyAreaLand({ form: formEle, boolean: true })
		// room
		propertyRoom({ form: formEle, boolean: false })
		// repair
		propertyRepair({ form: formEle, boolean: false })
		// building type
		propertyBuildingType({ form: formEle, boolean: false })
	} else if (propertyType == commercial) {
		// floor
		propertyFloor({ form: formEle, boolean: false })
		// area
		propertyArea({ form: formEle, boolean: true })
		// area land
		propertyAreaLand({ form: formEle, boolean: false })
		// room
		propertyRoom({ form: formEle, boolean: false })
		// repair
		propertyRepair({ form: formEle, boolean: true })
		// building type
		propertyBuildingType({ form: formEle, boolean: false })
	} else {
		// floor
		propertyFloor({ form: formEle, boolean: false })
		// area
		propertyArea({ form: formEle, boolean: false })
		// area land
		propertyAreaLand({ form: formEle, boolean: false })
		// room
		propertyRoom({ form: formEle, boolean: false })
		// repair
		propertyRepair({ form: formEle, boolean: false })
		// building type
		propertyBuildingType({ form: formEle, boolean: false })
	}
}

// property features
function propertyFeatures({ form, boolean }) {
	if (form.querySelector('.property-features-section')) {
		const section = form.querySelector('.property-features-section')
		if (boolean) {
			section.classList.remove('d-none')
		} else {
			section.classList.add('d-none')
		}
	}
}

// property rent type
function propertyRentType({ form, boolean }) {
	if (form.querySelector('.property-rent-type-section')) {
		const section = form.querySelector('.property-rent-type-section')
		if (boolean) {
			section.classList.remove('d-none')
			if (form == '.property-form') {
				section.querySelector('.rent_type').setAttribute('required', 'required')
			}
		} else {
			section.classList.add('d-none')
			if (form == '.property-form') {
				section.querySelector('.rent_type').removeAttribute('required')
			}
		}
	}
}

// property floor
function propertyFloor({ form, boolean }) {
	if (form.querySelector('.property-floor-section')) {
		const section = form.querySelector('.property-floor-section')
		const inputCrrentValue = section.querySelector('.floor')
		const inputAllValue = section.querySelector('.floor_all')
		if (boolean) {
			section.classList.remove('d-none')
			if (form == '.property-form') {
				inputCrrentValue.setAttribute('required', 'required')
				inputAllValue.setAttribute('required', 'required')
			}
			numberFormat({ boolean: true, section: inputCrrentValue, length: 3 })
			numberFormat({ boolean: true, section: inputAllValue, length: 3 })
		} else {
			section.classList.add('d-none')
			if (form == '.property-form') {
				inputCrrentValue.removeAttribute('required')
				inputAllValue.removeAttribute('required')
			}
			inputCrrentValue.value = ''
			inputAllValue.value = ''
			numberFormat({ boolean: false })
		}
	}
}

// property area
function propertyArea({ form, boolean }) {
	if (form.querySelector('.property-area-section')) {
		const section = form.querySelector('.property-area-section')
		const inputSection = section.querySelector('input')
		if (boolean) {
			section.classList.remove('d-none')
			if (form == '.property-form') {
				inputSection.setAttribute('required', 'required')
			}
			decimalPointFormat({ boolean: true, section: inputSection })
		} else {
			section.classList.add('d-none')
			if (form == '.property-form') {
				inputSection.removeAttribute('required')
			}
			inputSection.value = ''
			decimalPointFormat({ boolean: false })
		}
	}
}

// property area land
function propertyAreaLand({ form, boolean }) {
	if (form.querySelector('.property-area-land-section')) {
		const section = form.querySelector('.property-area-land-section')
		const inputSection = section.querySelector('input')
		if (boolean) {
			section.classList.remove('d-none')
			if (form == '.property-form') {
				inputSection.setAttribute('required', 'required')
			}
			decimalPointFormat({ boolean: true, section: inputSection })
		} else {
			section.classList.add('d-none')
			if (form == '.property-form') {
				inputSection.removeAttribute('required')
			}
			inputSection.value = ''
			decimalPointFormat({ boolean: false })
		}
	}
}

// property room
function propertyRoom({ form, boolean }) {
	if (form.querySelector('.property-room-section')) {
		const section = form.querySelector('.property-room-section')
		const inputSection = section.querySelector('.room')
		if (boolean) {
			section.classList.remove('d-none')
			if (form == '.property-form') {
				inputSection.setAttribute('required', 'required')
			}
		} else {
			section.classList.add('d-none')
			if (form == '.property-form') {
				inputSection.removeAttribute('required')
			}
			inputSection.value = ''
		}
	}
}

// property repair
function propertyRepair({ form, boolean }) {
	if(form.querySelector('.property-repair-section')) {
		const section = form.querySelector('.property-repair-section')
		if (boolean) {
			section.classList.remove('d-none')
		} else {
			section.classList.add('d-none')
		}
	}
}

// property building type
function propertyBuildingType({ form, boolean }) {
	if (form.querySelector('.property-building-type-section')) {
		const section = form.querySelector('.property-building-type-section')
		if(boolean) {
			section.classList.remove('d-none')
		} else {
			section.classList.add('d-none')
		}
	}
}

// select city
export function selectCity() {
	if (document.querySelector('select#city')) {
		const citySelect = document.querySelector('select#city')
		// change city
		citySelect.addEventListener("change", function() {
		// get selected city
		const citySelectedValue = $('select#city option:selected').val()
			if (citySelectedValue) {
				setTimeout(function() {
					$.ajax({
						method: "POST",
						dataType: "json",
						url: "" + base_url + "/ajax/select-city",
						data: {"city_id": citySelectedValue},
						success: function(response) {
							if (response.status === true) {
								if (!response.regions || response.regions == 0) {
									propertyRegion({ boolean: false })
								} else {
									propertyRegion({ boolean: true, data: response.regions })
								}
								propertySetCoordinate({ latitude: response.coordinate.latitude, longitude: response.coordinate.longitude })
							} else {
								propertyRegion({ boolean: false })
							}
							propertyTown({ boolean: false })
							propertyLandmark({ boolean: false })
						},
						error: function(response) {
							propertyRegion({ boolean: false })
						}
					})
				}, 100)
			}
		})
	}
}

// select region
export function selectRegion() {
	if (document.querySelector('select#region')) {
		const regionSelect = document.querySelector('select#region')
		// change region
		regionSelect.addEventListener("change", function() {
		// get selected region
		const regionSelectedValue = $('select#region option:selected').val()
			if (regionSelectedValue) {
				setTimeout(function() {
					$.ajax({
						method: "POST",
						dataType: "json",
						url: "" + base_url + "/ajax/select-region",
						data: {"region_id": regionSelectedValue},
						success: function(response) {
							if (response.status === true) {
								if (!response.towns || response.towns.length == 0) {
									propertyTown({ boolean: false })
								} else {
									propertyTown({ boolean: true, data: response.towns })
								}
								propertyLandmark({ boolean: false })
								if (!response.landmarks || response.landmarks.length == 0) {
									//
								} else {
									propertyLandmark({ boolean: true, data: response.landmarks })
								}
								propertySetCoordinate({ latitude: response.coordinate.latitude, longitude: response.coordinate.longitude })
							} else {
								propertyTown({ boolean: false })
								propertyLandmark({ boolean: false })
							}
						},
						error: function(response) {
							propertyTown({ boolean: false })
						}
					})
				}, 100)
			}
		})
	}
}

// select town
export function selectTown() {
	if (document.querySelector('select#town')) {
		const townSelect = document.querySelector('select#town')
		// change town
		townSelect.addEventListener("change", function() {
		// get selected town
		const townSelectedValue = $('select#town option:selected').val()
			if (townSelectedValue) {
				setTimeout(function() {
					$.ajax({
						method: "POST",
						dataType: "json",
						url: "" + base_url + "/ajax/select-town",
						data: {"town_id": townSelectedValue},
						success: function(response) {
							if (response.status === true) {
								propertyLandmark({ boolean: false })
								if (!response.landmarks || response.landmarks.length == 0) {
									//
								} else {
									propertyLandmark({ boolean: true, data: response.landmarks })
								}
								propertySetCoordinate({ latitude: response.coordinate.latitude, longitude: response.coordinate.longitude })
							}
						},
						error: function(response) {
							propertyTown({ boolean: false })
						}
					})
				}, 100)
			}
		})
	}
}

// property region
function propertyRegion({ boolean, data }) {
	if (document.querySelector('.property-region-section')) {
		const section = document.querySelector('.property-region-section')
		const select = section.querySelector('.region')
		if (boolean) {
			section.classList.remove('d-none')
			select.innerHTML = `<option value=""></option>`
			for (var i = 0; i < data.length; i++) {
				const optionEle = document.createElement('option')
				optionEle.setAttribute('value', data[i].id)
				optionEle.innerHTML = data[i].name
				select.appendChild(optionEle)
			}
		} else {
			section.classList.add('d-none')
			select.innerHTML = `<option value=""></option>`
		}
	}
}

// property town
function propertyTown({ boolean, data }) {
	if (document.querySelector('.property-town-section')) {
		const section = document.querySelector('.property-town-section')
		const select = section.querySelector('.town')
		if (boolean) {
			section.classList.remove('d-none')
			select.innerHTML = `<option value=""></option>`
			for (var i = 0; i < data.length; i++) {
				const optionEle = document.createElement('option')
				optionEle.setAttribute('value', data[i].id)
				optionEle.innerHTML = data[i].name
				select.appendChild(optionEle)
			}
		} else {
			section.classList.add('d-none')
			select.innerHTML = `<option value=""></option>`
		}
	}
}

// property landmark
function propertyLandmark({ boolean, data }) {
	if (document.querySelector('.property-landmark-section')) {
		const section = document.querySelector('.property-landmark-section')
		const list = section.querySelector('.landmarks-list ul')
		if (boolean) {
			section.classList.remove('d-none')
			let childEle = ''
			for (var i = 0; i < data.length; i++) {
				childEle += `<li>
				<input type="hidden" name="property[landmarks][]" value="${data[i].id}">
				<span class="pin">${landmarkTypeIcon(data[i].type)}</span>
				${data[i].name}
				<span class="remove-landmark"><i class="ri-close-line"></i></span>
				</li>`
			}
			list.innerHTML += childEle
			function landmarkTypeIcon(type) {
				if(type == '1') {
					return '<i class="ri-subway-line"></i>'
				} else if(type == '2') {
					return '<i class="ri-graduation-cap-line"></i>'
				} else if(type == '3') {
					return '<i class="ri-tree-line"></i>'
				} else if(type == '4') {
					return '<i class="ri-government-line"></i>'
				} else {
					return '<i class="ri-map-pin-line"></i>'
				}
			}
			removeLandmark({ boolean: true })
		} else {
			section.classList.add('d-none')
			list.innerHTML = ''
		}
	}
}

// remove landmark
function removeLandmark({ boolean }) {
	if (document.querySelector('.property-landmark-section')) {
		const section = document.querySelector('.property-landmark-section')
		if (boolean) {
			const landmarkEle = section.querySelectorAll('li')
			landmarkEle.forEach(el => {
				let removeBtn = el.querySelector('.remove-landmark')
				if (removeBtn) {
					removeBtn.addEventListener('click', function () {
						const landmarkLi = section.querySelectorAll('li')
						removeBtn.parentNode.remove()
						if(landmarkLi.length == 1) {
							section.classList.add('d-none')
						}
					})
				}
			})
		}
	}
}

// set coordinate
function propertySetCoordinate({ latitude, longitude }) {
	if (document.querySelector('.property-location-section')) {
		const locationSection = document.querySelector('.property-location-section')
		// set latitude
		if (locationSection.querySelector('.map-latitude')) {
			locationSection.querySelector('.map-latitude').setAttribute('value', latitude)
		}
		// set longitude
		if(locationSection.querySelector('.map-longitude')) {
			locationSection.querySelector('.map-longitude').setAttribute('value', longitude)
		}
		propertyResetMap({boolean: true})
	}
}

// property reset map
function propertyResetMap({ boolean }) {
	if (boolean) {
		if (document.querySelector('.property-location-section')) {
			const locationSection = document.querySelector('.property-location-section')
			const mapBtn = locationSection.querySelector('.map-btn')
			mapBtn.style.display = 'block'
			const staticMap = locationSection.querySelector('.static-map')
			staticMap.innerHTML = ''
		}
	}
}

// property set location
export function propertySetLocation() {
	if (document.querySelector('.property-form')) {
		// open map
		if (document.querySelector('.set-location')) {
			const locationBtn = document.querySelector('.set-location')
			locationBtn.addEventListener("click", function() {
				showMap()
				return false
			})
		}
		// set location
		if (document.querySelector('.btn-set-location')) {
			const setBtn = document.querySelector('.btn-set-location')
			const mapBtn = document.querySelector('.map-btn')
			setBtn.addEventListener("click", function() {
				mapBtn.style.display = 'none'
				showStaticMap()
				return false
			})
		}
		// show static map
		function showStaticMap() {
			const locationSection = document.querySelector('.property-location-section')
			let mapLat = locationSection.querySelector('.map-latitude').value
			var mapLon = locationSection.querySelector('.map-longitude').value
			if (mapLat != '' && mapLon != '') {
				$(".static-map").html('<img src="https://maps.googleapis.com/maps/api/staticmap?center=' + mapLat + ',' + mapLon + '&language=az&zoom=13&scale=1&size=576x160&maptype=roadmap&markers=color:red%7C' + mapLat + ',' + mapLon + '&key=' + google_map_api_key + '" />')
			}
		}
		// open map
		if(document.querySelector('.google-map')) {
			showMap()
		}
		// set latitude & longitude
		function setLatLng(latLng) {
			if (document.querySelector('.property-location-section')) {
				const locationSection = document.querySelector('.property-location-section')
				// fixed latitude & longitude
				let latitude = latLng.lat().toFixed(6)
				let longitude = latLng.lng().toFixed(6)
				// set latitude
				if (locationSection.querySelector('.map-latitude')) {
					locationSection.querySelector('.map-latitude').setAttribute('value', latitude)
				}
				// set longitude
				if (locationSection.querySelector('.map-longitude')) {
					locationSection.querySelector('.map-longitude').setAttribute('value', longitude)
				}
			}
		}
		// show map
		function showMap() {
			const locationSection = document.querySelector('.property-location-section')
			var mapLat = locationSection.querySelector('.map-latitude').value
			var mapLon = locationSection.querySelector('.map-longitude').value
			if (mapLat != '' && mapLon != '') {
				var map = null
				var latlng = new google.maps.LatLng(mapLat, mapLon)
				var myOptions = {
					zoom: 13,
					center: latlng,
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					size: new google.maps.Size(764,400),
				}
				map = new google.maps.Map(document.getElementById("locationMap"), myOptions);
				var marker = new google.maps.Marker({
					map: map,
					draggable: true,
					position: latlng
				})
				google.maps.event.addListener(map, 'click', function(event) {
					marker.setPosition(event.latLng);
					setLatLng(event.latLng);
				})
				marker.setMap(map)
			} else {
				var map = null
				var myOptions = {
					zoom: 13,
					center: new google.maps.LatLng(40.3925807196913, 49.8422241210938),
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					size: new google.maps.Size(764,400),
				}
				map = new google.maps.Map(document.getElementById("locationMap"), myOptions)
				latlng = new google.maps.LatLng(40.3925807196913, 49.8422241210938)
				var marker = new google.maps.Marker({
					map: map,
					draggable: true,
					position: latlng
				})
				google.maps.event.addListener(marker, 'dragend', function(evt) {
					setLatLng(evt.latLng)
				})
				google.maps.event.addListener(map, 'click', function(event) {
					marker.setPosition(event.latLng);
					setLatLng(event.latLng);
				})
				map.setCenter(marker.position)
				marker.setMap(map)
			}
		}
	}
}