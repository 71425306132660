"use strict";

import { numberFormat, lazyLoadImages, phoneNumberFormat } from "./functions";
import { selectCity, selectRegion, selectTown } from "./showHidePropertySections";

// follow/unfollow agency
function followAgency() {
	const followBtn = document.querySelectorAll('.follow-button');
	for (let i = 0; i < followBtn.length; i++) {
		followBtn[i].index = i
		followBtn[i].addEventListener("click", function() {
			if ($(this).data("agency")) {
				const agencyId = $(this).data("agency")
				if (agencyId) {
					setTimeout(function() {
						$.ajax({
							method: "POST",
							dataType: "json",
							url: "" + base_url + "/ajax/follow-agency",
							data: {
								"id": agencyId
							},
							success: function(response) {
								response.success
								followBtn[i].innerHTML = response.success
							},
							error: function(response) {
								//
							}
						})
					}, 1000)
				}
			}
		})
	}
}

// agreement
function clickAgreementBox() {
    if (document.querySelector('.agreement')) {
        const submitBtn = document.querySelector('.submit-button')
        const agreementBox = document.querySelector('.agreement')
        agreementBox.addEventListener("click", function() {
            var checked_status = agreementBox.checked;
            if (checked_status == true) {
                submitBtn.removeAttribute('disabled')
            } else {
                submitBtn.setAttribute('disabled', '')
            }
        })
    }
}

// property price
function priceFormat() {
	if (document.querySelector('.price-filter')) {
		const section = document.querySelector('.price-filter')
		const inputSection = section.querySelectorAll('input')
		for (let i = 0; i < inputSection.length; i++) {
			numberFormat({ boolean: true, section: inputSection[i], length: 9 })
		}
	}
}

// view property phone
function viewPropertyPhone() {
	if (document.querySelector('.contact-phone, .property-contact')) {
		const phoneEle = document.querySelector('.contact-phone')
		const viewBtn = phoneEle.querySelector('.btn-view-number')
		if (document.querySelector('.property-contact')) {
			const callBtn = document.querySelector('.property-contact').querySelector('.btn-call-number')
			callBtn.addEventListener("click", function() {
				const propertyId = $(this).data("property")
				actionPhone({ property: propertyId })
			})
		}
		viewBtn.addEventListener("click", function() {
			const propertyId = $(this).data("property")
			actionPhone({ property: propertyId })
		})
		function actionPhone({ property }) {
			if (property) {
				setTimeout(function() {
					$.ajax({
						method: "POST",
						dataType: "json",
						url: "" + base_url + "/ajax/property/view-phone",
						data: {'id': property},
						success: function(response) {
							if(response.status == true) {
								window.open('tel:' + response.phone_full + '', '_parent')
								viewBtn.remove()
								phoneEle.innerHTML = '<a class="btn btn-contact full-width" href="tel:' + response.phone_full + '"><i class="ri-phone-line"></i> ' + response.phone + '</a>'
							}
						},
						error: function(response) {
							//
						}
					})
				}, 300)
			}
		}
	}
}

// property images
function propertyImages() {
	if (document.querySelector('.property-images-list')) {
		const $counter = $('.property-images-count');
		const images = $('.property-images-list')
		// image count
		images.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
			//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
			const i = (currentSlide ? currentSlide : 0) + 1;
			$counter.html( '<span class="current_slide">' + i + '</span> / <span class="total_slides"> ' + slick.slideCount + '</span>');
		});
		images.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			asNavFor: '.property-images-nav'
		});
		$('.property-images-nav').slick({
			slidesToShow: 8,
			slidesToScroll: 1,
			infinite: true,
			arrows: false,
			asNavFor: images,
			dots: false,
			focusOnSelect: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 8,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 6,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1
					}
				}
			]
		});
		$('a[data-slide]').click(function(e) {
			e.preventDefault();
			var slideno = $(this).data('slide');
			$('.property-images-nav').slick('slickGoTo', slideno - 1);
		});
	}
}

// pay new property modal
function payForNewProperty() {
	if (document.getElementById('propertyNewModal')) {
        const modalEle = document.getElementById('propertyNewModal')
		const propertyInput = modalEle.querySelector('.property-id')
		propertyInput.setAttribute('value', '')
		let propertyId
		// modal
		modalEle.addEventListener('show.bs.modal', function(e) {
			propertyId = $(e.relatedTarget).data('property')
			setInput({ id: propertyId })
		})
		// offcanvas
		modalEle.addEventListener('show.bs.offcanvas', function(e) {
			propertyId = $(e.relatedTarget).data('property')
			setInput({ id: propertyId })
		})
		// set input
		function setInput({ target : target }) {
			propertyInput.setAttribute('value', id)
		}
	}
}

// renew property modal
function renewProperty() {
	if (document.getElementById('propertyRenewModal')) {
        const modalEle = document.getElementById('propertyRenewModal')
		const propertyInput = modalEle.querySelector('.property-id')
		propertyInput.setAttribute('value', '')
		let propertyId
		// modal
		modalEle.addEventListener('show.bs.modal', function(e) {
			propertyId = $(e.relatedTarget).data('property')
			setInput({ id: propertyId })
		})
		// offcanvas
		modalEle.addEventListener('show.bs.offcanvas', function(e) {
			propertyId = $(e.relatedTarget).data('property')
			setInput({ id: propertyId })
		})
		// set input
		function setInput({ id : id }) {
			propertyInput.setAttribute('value', id)
		}
	}
}

// delete property modal
function deleteProperty() {
    if (document.querySelector(".property-delete-modal")) {
        const modalEle = document.querySelector(".property-delete-modal")
        let actionUrl, propertyId
        $('#propertyDeleteModal').on('show.bs.modal', function(e) {
            modalEle.querySelector('form').setAttribute('action', '')
			modalEle.querySelector('input[name="id"]').value = '';
            actionUrl = $(e.relatedTarget).data('action')
			propertyId = $(e.relatedTarget).data('property')
            modalEle.querySelector('form').setAttribute('action', actionUrl)
			$(e.currentTarget).find('input[name="id"]').val(propertyId)
        })
		$('#propertyDeleteModal').on('hidden.bs.modal', function (e) {
			modalEle.querySelector('form').setAttribute('action', '')
			modalEle.querySelector('input[name="id"]').value = '';
		})
    }
}

// forward property modal
function propertyForward() {
	$('#propertyForward').on('show.bs.modal', function(e) {
		if(document.querySelector(".property-forward-modal")) {
			const modalEle = document.querySelector(".property-forward-modal")
			if(modalEle.querySelector('form')) {
				modalEle.querySelector('.property-id').setAttribute('value', '')
				let propertyId = $(e.relatedTarget).data('property')
				modalEle.querySelector('.property-id').setAttribute('value', propertyId)
				// check balance
				checkUserBalance({ section: modalEle })
			}
		}
	})
}

// vip property modal
function propertyVip() {
	$('#propertyVipModal').on('show.bs.modal', function(e) {
		if (document.querySelector(".property-vip-modal")) {
			const modalEle = document.querySelector(".property-vip-modal")
			if (modalEle.querySelector('form')) {
				modalEle.querySelector('.property-id').setAttribute('value', '')
				let propertyId = $(e.relatedTarget).data('property')
				modalEle.querySelector('.property-id').setAttribute('value', propertyId)
				// check balance
				checkUserBalance({ section: modalEle })
			}
		}
	})
}

// premium property modal
function propertyPremium() {
	$('#propertyPremiumModal').on('show.bs.modal', function(e) {
		if (document.querySelector(".property-premium-modal")) {
			const modalEle = document.querySelector(".property-premium-modal")
			if (modalEle.querySelector('form')) {
				modalEle.querySelector('.property-id').setAttribute('value', '')
				let propertyId = $(e.relatedTarget).data('property')
				modalEle.querySelector('.property-id').setAttribute('value', propertyId)
				// check balance
				checkUserBalance({ section: modalEle })
			}
		}
	})
}

// ad property modal
function propertyAd() {
	$('#propertyAdModal').on('show.bs.modal', function(e) {
		if (document.querySelector(".property-ad-modal")) {
			const modalEle = document.querySelector(".property-ad-modal")
			if (modalEle.querySelector('form')) {
				modalEle.querySelector('.property-id').setAttribute('value', '')
				let propertyId = $(e.relatedTarget).data('property')
				modalEle.querySelector('.property-id').setAttribute('value', propertyId)
				// check balance
				checkUserBalance({ section: modalEle })
			}
		}
	})
}

// check service
function checkUserBalance({ section }) {
    if (section) {
		const serviceList = section.querySelectorAll(".service-input")
		for (let i = 0; i < serviceList.length; i++) {
			serviceList[i].index = i
			serviceList[i].addEventListener("change", function() {
				const serviceId = serviceList[i].value
				if (serviceId) {
					setTimeout(function() {
						$.ajax({
							method: "POST",
							dataType: "json",
							url: "" + base_url + "/ajax/user/check-balance",
							data: {"id": serviceId},
							success: function(response) {
								if(section.querySelector(".payment-with-balance")) {
									const balanceArea = section.querySelector(".payment-with-balance")
									if(response.status == true) {
										balanceArea.classList.remove('d-none')
									} else {
										balanceArea.classList.add('d-none')
									}
								}
							},
							error: function(response) {
								//
							}
						})
					}, 100)
				}
			})
		}
    }
}

// pin property modal
function pinProperty() {
    if (document.getElementById('propertyPinModal')) {
        const modalEle = document.getElementById('propertyPinModal')
        let propertyId, action, pin
		if (modalEle.querySelector('.property-pin-form')) {
			const formEle = modalEle.querySelector('.property-pin-form')
			const messageEle = modalEle.querySelector('.result-message')
			const propertyInput = modalEle.querySelector('.property-id')
			const actionInput = modalEle.querySelector('.action')
			const pinInput = modalEle.querySelector('.property-pin')
			// modal
			modalEle.addEventListener('show.bs.modal', function(e) {
				setInput({ target: e })
			})
			// offcanvas
			modalEle.addEventListener('show.bs.offcanvas', function(e) {
				setInput({ target: e })
			})
			// request
			formEle.addEventListener('submit', function(e) {
				e.preventDefault();
				// get input data
				propertyId = propertyInput.value
				action = actionInput.value
				pin = pinInput.value
				if (propertyId && action && pin) {
					setTimeout(function() {
						$.ajax({
							method: "POST",
							dataType: "json",
							url: "" + base_url + "/ajax/property/check-pin",
							data: {
								'id': propertyId,
								'action': action,
								'pin': pin
							},
							success: function(response) {
								messageEle.innerHTML = response.message
								if (response.status == true) {
									if (action == 'edit') {
										setTimeout(function() {
											window.location.href = base_url + "/elan/" + propertyId + "/edit/?pin=" + pin + "&token=" + response.token
										}, 3000)
									} else if (action == 'delete') {
										setTimeout(function() {
											location.reload()
										}, 3000);
									}
								}
							},
							error: function(response) {
								console.log(response)
							}
						})
					}, 300)
				} else {
					messageEle.innerHTML = 'Pin (şifrə) daxil edin'
				}
			})
			// set input
			function setInput({ target : target }) {
				messageEle.innerHTML = ''
				pinInput.value = ''
				propertyInput.setAttribute('value', $(target.relatedTarget).data('property'))
				actionInput.setAttribute('value', $(target.relatedTarget).data('action'))
			}
		}
    }
}

// report property modal
function reportProperty() {
	if (document.getElementById('propertyReportModal')) {
        const reportEle = document.querySelector('.add-report')
        const modalEle = document.getElementById('propertyReportModal')
        let propertyId, reportSelect, reportComment
		const contentEle = modalEle.querySelector('.content-section')
		const formEle = modalEle.querySelector('.property-report-form')
		const messageEle = modalEle.querySelector('.result-message')
		const propertyInput = modalEle.querySelector('.property-id')
		const reportInput = modalEle.querySelector('.property-report-select')
		const commentInput = modalEle.querySelector('.property-report-comment')
		// modal
		modalEle.addEventListener('show.bs.modal', function(e) {
			setInput({ target: e })
		})
		// offcanvas
		modalEle.addEventListener('show.bs.offcanvas', function(e) {
			setInput({ target: e })
		})
		// request
		formEle.addEventListener('submit', function(e) {
			e.preventDefault();
			// get input data
			propertyId = propertyInput.value
			reportSelect = reportInput.value
			reportComment = commentInput.value
			if (propertyId && reportSelect && reportComment) {
				setTimeout(function() {
					$.ajax({
						method: "POST",
						dataType: "json",
						url: "" + base_url + "/ajax/property/report",
						data: {
							'id': propertyId,
							'report': reportSelect,
							'comment': reportComment
						},
						success: function(response) {
							if(response.status == true) {
								contentEle.innerHTML = '<div class="text-center" style="padding:20px;">' + response.message + '</div>'
								reportEle.innerHTML = '<div class="text-danger"><i class="ri-checkbox-circle-line"></i> Şikayət etmisiniz</div>'
							}
						},
						error: function(response) {
							console.log(response)
						}
					})
				}, 300)
			} else {
				messageEle.innerHTML = 'Qeyd olunan bölmələr doldurulmalıdır'
			}
		})
		// set input
		function setInput({ target : target }) {
			messageEle.innerHTML = ''
			propertyInput.setAttribute('value', $(target.relatedTarget).data('property'))
		}
    }
}

// number area
function numberArea() {
	if(document.querySelectorAll('.property-price-section, .property-area-section, .property-area-land-section, .property-floor-range, .property-number-section')) {
		const section = document.querySelectorAll('.property-price-section, .property-area-section, .property-area-land-section, .property-floor-range, .property-number-section')
		for (var s = 0; s < section.length; s++) {
			const inputSection = section[s].querySelectorAll('input')
			for (var i = 0; i < inputSection.length; i++) {
				numberFormat({ boolean: true, section: inputSection[i], length: 9 })
			}
		}
	}
}

// onload
window.addEventListener('load', function () {
	clickAgreementBox()
	priceFormat()
	phoneNumberFormat()
	numberArea()
	// property action
	viewPropertyPhone()
	payForNewProperty()
	renewProperty()
	deleteProperty()
	// property service
	propertyVip()
	propertyPremium()
	propertyAd()
	// select
	selectCity()
	selectRegion()
	selectTown()
})

document.addEventListener("DOMContentLoaded", function() {
	lazyLoadImages()
	propertyImages()
	reportProperty()
	pinProperty()
});
